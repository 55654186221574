import { 
    LOGIN_USER,
    LOGOUT_USER,
    SET_LOADING,
    SET_PROFILE,
  } from "../actions/types"

const initialState = {
  user: JSON.parse(typeof window !== 'undefined' && localStorage.getItem('originall-fii-user')),
  loading: false,
  profile: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case LOGIN_USER:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return { ...state, user: null };
    case SET_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.payload } };
    default:
      return { ...state };
  }
};