import {
  GET_MAP_DATA,
  SET_LOADING,
  GET_REPORT_DATA,
  SET_LOADING_MAP,
  GET_LOGS_DATA,
  GET_NEW_LOGS_DATA
} from '../actions/types';

const INIT_STATE = {
  aggregatedData: {},
  logsData: [],
  mapData: [],
  isLoading: false,
  loadingMap: false,
  newLogs: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_LOADING_MAP:
      return { ...state, loadingMap: action.payload };
    case GET_REPORT_DATA: {
      if (state.aggregatedData.total_verify_count !== action.payload.total_verify_count) {
        return { ...state, aggregatedData: action.payload, isLoading: false };
      } else {
        return { ...state };
      }
    }
    case GET_LOGS_DATA: {
      if (JSON.stringify(state.logsData) !== JSON.stringify(action.payload))
        return { ...state, logsData: action.payload, isLoading: false, isError: false };
      else
        return { ...state };
    }
    case GET_NEW_LOGS_DATA: {
      if (JSON.stringify(state.logsData) !== JSON.stringify(action.payload)) {
        let difference = action.payload.filter(x => !state.logsData.map(y => y.id).includes(x.id));
        return { ...state, logsData: action.payload, newLogs: difference, isLoading: false, isError: false };
      }
      else
        return { ...state };
    }
    case GET_MAP_DATA: {
      if (JSON.stringify(state.mapData) !== JSON.stringify(action.payload))
        return { ...state, mapData: action.payload, isLoading: false, isError: false };
      else
        return { ...state };
    }
    default:
      return { ...state };
  }
};