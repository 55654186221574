// TYPES
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_PROFILE = 'SET_PROFILE';
export const GET_MAP_DATA = "GET_MAP_DATA";
export const SET_PAGES_COUNT = "SET_PAGES_COUNT";
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const SET_LOADING_MAP = "SET_LOADING_MAP"
export const GET_LOGS_DATA = "GET_LOGS_DATA";
export const GET_NEW_LOGS_DATA = "GET_NEW_LOGS_DATA";